import {
  SettingBroadcast,
  SettingCoverAndLive,
  SettingDirectory,
  SettingMisc,
  SettingRadio,
  SettingSecurity,
  SettingSocial,
  SettingStream,
} from '@app/library/models/setting.model';

const tag = '[Setting]';

export class SettingRadioRequest {
  static readonly type = `${tag} request setting radio`;
  constructor(public id: number) {}
}

export class SettingRadioSuccess {
  static readonly type = `${tag} success setting radio`;
  constructor(
    public setting: SettingRadio,
    public id: number,
  ) {}
}

export class SettingRadioFailure {
  static readonly type = `${tag} error setting radio`;
  constructor(public error: Error) {}
}

export class UpdateSettingRadioRequest {
  static readonly type = `${tag} request update setting radio`;
  constructor(
    public setting: Partial<SettingRadio>,
    public file?: File,
  ) {}
}

export class UpdateSettingRadioSuccess {
  static readonly type = `${tag} success update setting radio`;
  constructor(public setting: SettingRadio) {}
}

export class UpdateSettingRadioFailure {
  static readonly type = `${tag} error update setting radio`;
  constructor(public error: Error) {}
}

export class SettingBroadcastRequest {
  static readonly type = `${tag} request setting broadcast`;
  constructor(public id: number) {}
}

export class SettingBroadcastSuccess {
  static readonly type = `${tag} success setting broadcast`;
  constructor(public setting: SettingBroadcast) {}
}

export class SettingBroadcastFailure {
  static readonly type = `${tag} error setting broadcast`;
  constructor(public error: Error) {}
}

export class UpdateSettingBroadcastRequest {
  static readonly type = `${tag} request update setting broadcast`;
  constructor(public setting: SettingBroadcast) {}
}

export class UpdateSettingBroadcastSuccess {
  static readonly type = `${tag} success update setting broadcast`;
  constructor(public setting: SettingBroadcast) {}
}

export class UpdateSettingBroadcastFailure {
  static readonly type = `${tag} error update setting broadcast`;
  constructor(public error: Error) {}
}

export class SettingStreamRequest {
  static readonly type = `${tag} request setting stream`;
  constructor(public id: number) {}
}

export class SettingStreamSuccess {
  static readonly type = `${tag} success setting stream`;
  constructor(public setting: SettingStream) {}
}

export class SettingStreamFailure {
  static readonly type = `${tag} error setting stream`;
  constructor(public error: Error) {}
}

export class UpdateSettingStreamRequest {
  static readonly type = `${tag} request update setting stream`;
  constructor(public setting: SettingStream) {}
}

export class UpdateSettingStreamSuccess {
  static readonly type = `${tag} success update setting stream`;
  constructor(public setting: SettingStream) {}
}

export class UpdateSettingStreamFailure {
  static readonly type = `${tag} error update setting stream`;
  constructor(public error: Error) {}
}

export class SettingCoverAndLiveRequest {
  static readonly type = `${tag} request setting cover and live`;
  constructor(public id: number) {}
}

export class SettingCoverAndLiveSuccess {
  static readonly type = `${tag} success setting cover and live`;
  constructor(public setting: SettingCoverAndLive) {}
}

export class SettingCoverAndLiveFailure {
  static readonly type = `${tag} error setting cover and live`;
  constructor(public error: Error) {}
}

export class UpdateSettingCoverAndLiveRequest {
  static readonly type = `${tag} request update setting cover and live`;
  constructor(
    public setting: SettingCoverAndLive,
    public defaultCover?: File | string | boolean,
    public liveCover?: File | string | boolean,
  ) {}
}

export class UpdateSettingCoverAndLiveSuccess {
  static readonly type = `${tag} success update setting cover and live`;
  constructor(public setting: SettingCoverAndLive) {}
}

export class UpdateSettingCoverAndLiveFailure {
  static readonly type = `${tag} error update setting cover and live`;
  constructor(public error: Error) {}
}

export class SettingDirectoryRequest {
  static readonly type = `${tag} request setting directory`;
  constructor(public id: number) {}
}

export class SettingDirectorySuccess {
  static readonly type = `${tag} success setting directory`;
  constructor(public setting: SettingDirectory) {}
}

export class SettingDirectoryFailure {
  static readonly type = `${tag} error setting directory`;
  constructor(public error: Error) {}
}

export class UpdateSettingDirectoryRequest {
  static readonly type = `${tag} request update setting directory`;
  constructor(public allDirectoriesEnabled: boolean) {}
}

export class UpdateSettingDirectorySuccess {
  static readonly type = `${tag} success update setting directory`;
  constructor(public setting: SettingDirectory) {}
}

export class UpdateSettingDirectoryFailure {
  static readonly type = `${tag} error update setting directory`;
  constructor(public error: Error) {}
}

export class SettingSocialRequest {
  static readonly type = `${tag} request setting social`;
  constructor(public id: number) {}
}

export class SettingSocialSuccess {
  static readonly type = `${tag} success setting social`;
  constructor(public setting: SettingSocial) {}
}

export class SettingSocialFailure {
  static readonly type = `${tag} error setting social`;
  constructor(public error: Error) {}
}
export class UpdateSettingSocialRequest {
  static readonly type = `${tag} request update setting social`;
  constructor(public setting: SettingSocial) {}
}

export class UpdateSettingSocialSuccess {
  static readonly type = `${tag} success update setting social`;
  constructor(public setting: SettingSocial) {}
}

export class UpdateSettingSocialFailure {
  static readonly type = `${tag} error update setting social`;
  constructor(public error: Error) {}
}

export class SettingSecurityRequest {
  static readonly type = `${tag} request setting security`;
  constructor(public id: number) {}
}

export class SettingSecuritySuccess {
  static readonly type = `${tag} success setting security`;
  constructor(public setting: SettingSecurity) {}
}

export class SettingSecurityFailure {
  static readonly type = `${tag} error setting security`;
  constructor(public error: Error) {}
}

export class UpdateSettingSecurityRequest {
  static readonly type = `${tag} request update setting security`;
  constructor(public setting: SettingSecurity) {}
}

export class UpdateSettingSecuritySuccess {
  static readonly type = `${tag} success update setting security`;
  constructor(public setting: SettingSecurity) {}
}

export class UpdateSettingSecurityFailure {
  static readonly type = `${tag} error update setting security`;
  constructor(public error: Error) {}
}

export class SettingMiscRequest {
  static readonly type = `${tag} request setting misc`;
  constructor(public id: number) {}
}

export class SettingMiscSuccess {
  static readonly type = `${tag} success setting misc`;
  constructor(public setting: SettingMisc) {}
}

export class SettingMiscFailure {
  static readonly type = `${tag} error setting misc`;
  constructor(public error: Error) {}
}

export class UpdateSettingMiscRequest {
  static readonly type = `${tag} request update setting misc`;
  constructor(public setting: SettingMisc) {}
}

export class UpdateSettingMiscSuccess {
  static readonly type = `${tag} success update setting misc`;
  constructor(public setting: SettingMisc) {}
}

export class UpdateSettingMiscFailure {
  static readonly type = `${tag} error update setting misc`;
  constructor(public error: Error) {}
}

export class StartupRadioRequest {
  static readonly type = `${tag} request startup radio`;
  constructor(public radio: Partial<SettingRadio>) {}
}

export class StartupRadioSuccess {
  static readonly type = `${tag} success startup radio`;
  constructor(
    public id: number,
    public setting: SettingRadio,
  ) {}
}

export class StartupRadioFailure {
  static readonly type = `${tag} error startup radio`;
  constructor(public error: Error) {}
}

export class CheckSlugRequest {
  static readonly type = `${tag} request check slug`;
  constructor(public slug: string) {}
}

export class CheckSlugSuccess {
  static readonly type = `${tag} success check slug`;
}

export class CheckSlugFailure {
  static readonly type = `${tag} error check slug`;
  constructor(public error: Error) {}
}

export class UpdateNeedRestartRadio {
  static readonly type = `${tag} update need a restart modal`;
}
